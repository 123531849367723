<template>
  <div class="pageMain">
    <div v-if="infoShow">
      <search-head
        :model="searchForm"
        @search="search"
        @reset="reset"
        :isExport="true"
      >
        <el-form :inline="true" :model="searchForm">
          <!-- <el-form-item label="所属机构" prop="orgaIdArr">
              <el-cascader
                  size="small"
                  class="block-select"
                  v-model="searchForm.orgaIdArr"
                  :options="copanyListInfo"
                  popper-class="custom-cascader"
                  filterable
                  :props="{
                    checkStrictly: true,
                    expandTrigger: 'hover',
                   
                  }"
                  @change="cascaderEditChange">
              </el-cascader>
            </el-form-item> -->
          <el-form-item label="公司名称">
            <el-input
              v-model="searchForm.compName"
              size="small"
              placeholder="公司名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="项目名称">
            <el-input
              v-model="searchForm.projName"
              size="small"
              placeholder="项目名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select
              v-model="searchForm.deviceType"
              size="small"
              placeholder="设备类型"
            >
              <el-option
                v-for="item in equipType"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="设备IMEI">
            <el-input
              v-model="searchForm.deviceImei"
              size="small"
              placeholder="设备IMEI"
            ></el-input>
          </el-form-item>
          <el-form-item label="现场编号">
            <el-input
              v-model="searchForm.deviceNo"
              size="small"
              placeholder="现场编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="状态">
            <el-select
              v-model="searchForm.deviceOnline"
              size="small"
              placeholder="状态"
            >
              <el-option
                v-for="item in deviceOnline"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="机器版本">
            <el-select
              v-model="searchForm.generation"
              size="small"
              placeholder="机器版本"
            >
              <el-option
                v-for="item in generationList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </search-head>
      <custom-tables
        :data="tableData"
        :headerCellStyle="headerCellStyle"
        @selectionChange="handleSelectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :total="total"
        title="项目设备"
      >
        <!-- <template slot="btns">
            <el-button type="primary" size="small" @click="addFrom">新建</el-button>
          </template> -->
        <el-table-column type="selection" align="center" width="55">
        </el-table-column>
        <el-table-column prop="projName" label="项目名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="orgaPathName" label="公司" show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="deviceImei"
          label="设备IMEI"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="deviceType"
          label="设备类型"
          width="80"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.deviceType == 0">塔吊</span>
            <span v-if="scope.row.deviceType == 1">升降机</span>
            <span v-if="scope.row.deviceType == 2">龙门吊</span>
            <span v-if="scope.row.deviceType == 3">架桥机</span>
            <span v-if="scope.row.deviceType == 4">爬模</span>
            <span v-if="scope.row.deviceType == 5">挂篮</span>
            <span v-if="scope.row.deviceType == 8">扬尘</span>
            <span v-if="scope.row.deviceType == 9">喷淋</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="deviceNo"
          label="现场编号"
          width="80"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="generation"
          label="机器版本"
          width="80"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="deviceOnline"
          align="center"
          label="状态"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.deviceOnline == 0" style="color: #008f4d"
              >在线</span
            >
            <span v-if="scope.row.deviceOnline == 1" style="color: red"
              >离线</span
            >
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <span @click="detailItem(scope.row)" class="detail">查看</span>
          </template>
        </el-table-column>
      </custom-tables>
    </div>
    <!-- <monitorAlarm v-if="editShow" :compId="compId"></monitorAlarm> -->
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import CustomDialog from "../../../components/common/CustomDialog";
import organizationTree from "../../../api/modules/organizationTree";
import monitor from "../../../api/modules/monitor";
import {
  equipType,
  deviceOnline,
  generationList,
} from "../../../config/dataStatus";
import {
  organizationCascaders,
  phoneReg,
  organizationCascader,
} from "../../../utils/tool";

export default {
  name: "index",
  components: { CustomDialog, CustomTables, SearchHead },
  data() {
    return {
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      copanyListInfo: [],
      total: 0,
      showDialog: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      editShow: false,
      infoShow: true,
      compId: "",
      deviceOnline: deviceOnline,
      equipType: equipType,
      generationList: generationList,
    };
  },
  mounted() {
    this.searchForm.projId = this.$route.query.projId;
    this.getList();
    this.getCopanyInfo();
  },
  methods: {
    getCopanyInfo() {
      organizationTree.getOrganizationCompanyTree().then((res) => {
        // 递归遍历 生成 {value, label, children}

        // let data = organizationCascaders(res.data);
        // console.log('data',data);

        this.copanyListInfo = organizationCascader(res.data);
      });
    },
    getList() {
      let params = {
        currPage: this.searchForm.currPage,
        pageSize: this.searchForm.pageSize,
        compName: this.searchForm.compName,
        projName: this.searchForm.projName,
        deviceType: this.searchForm.deviceType,
        deviceImei: this.searchForm.deviceImei,
        deviceOnline: this.searchForm.deviceOnline,
        generation: this.searchForm.generation,
        deviceNo: this.searchForm.deviceNo,
        projId: this.searchForm.projId
      };

      monitor.getDeviceSelect(params).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    cascaderEditChange(val) {
      console.log("val", val);

      let valLen = val.length;
      this.searchForm.orgaId = val[valLen - 1];
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
    },
    addFrom() {
      this.showDialog = true;
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getList();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.currPage = 1;
      this.getList();
    },
    close() {
      this.showDialog = false;
    },
    submit() {
      this.showDialog = false;
    },
    detailItem(item) {
      //详情
      if (item.generation == 6) {
        this.$router.push({
          path: `/screnDetail`,
          query: {
            url: item.cameChannel,
            type: item.type,
            title: item.deviceImei,
            deviceType: item.deviceType,
            projId: item.projId,
            deviceOnline: item.deviceOnline,
            deviceId: item.deviceId,
            deviceNo: item.deviceNo,
          },
        });
      } else if (item.generation == 5) {
        this.$router.push({
          path: `/fiveScren`,
          query: {
            url: item.cameChannel,
            type: item.type,
            title: item.deviceImei,
            deviceType: item.deviceType,
            projId: item.projId,
            deviceOnline: item.deviceOnline,
            deviceId: item.deviceId,
            deviceNo: item.deviceNo,
          },
        });
      }else if(item.generation==4){
        this.$router.push({
        path: `/FugitiveScren`,
        query: {
          url:item.cameChannel,
          type:item.type,
          title:item.deviceImei,
          deviceType:item.deviceType,
          projId:item.projId,
          deviceOnline:item.deviceOnline,
          deviceId:item.deviceId,
          deviceNo:item.deviceNo
        },
      });
      }
    },
    handleSelectionChange(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped lang="less"></style>
